
.error-text {
  color: #EE2D55;
}

.cc-form-container {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.form-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.ui.button {
    display: inline-block;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 4px;
    height: 48px;
    width: 160px;
    font-family: 'Source Sans Pro', sans-serif; 
    font-weight: 700;
    font-size: 18px;
    margin-right: 13px;
    white-space: nowrap;
    color: $white;
    background-color: $om-aqua;
    &:hover, &:focus {
        color: $white;
        background-color: $om-aqua;
    }
}

.ui.button.button-hollow {
    color: $om-aqua;
    background-color: $white;
    border: 1px solid $om-aqua;
}

.ui.button.button-completion {
    width: 100%;
}

.ui.form .ui.dropdown {
    height: 48px;
    text-indent: 8px;
}

.ui.form .field > label, .ui.form .field.error label {
    font-weight: normal;
    color: $ui-grey-2;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 4px;
}

.ui.form .field > .validation-message {
    color: $utility-red;
    font-size: 12px;
}

.ui.form .field > .assistive-text {
    font-size: 12px;
    color: $ui-grey-2;
}

input[type=text], .ui.form input[type=text], input[type=password], 
.ui.form input[type=password], textarea, .ui.form textarea {
    font-family: 'Source Sans Pro', sans-serif;
    border-color: $ui-grey-3;
    ::placeholder {
        color: $ui-grey-2;
    }
    padding: 12px 16px;
    height: 48px;
}

.ui.form .field>label, .ui.form .field.error>label {
    color: #707070;
    font-weight: 400;
    font-family: "Source Sans Pro"
}
  
.ui.form .field.error input[type=text] {
    border-color: #EE2D55;
    &::placeholder {
      color: #EE2D55;
    }
}

.ui.checkbox label, .ui.checkbox input:focus~label {
    color: $ui-grey-1;
}

.ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
    background: $off-white;
}

.ui.checkbox:not(.toggle):not(.radio) {
    input:checked~.box:after, input:checked~label:after {
        content: '';
        background-image: url("data:image/svg+xml;utf8,<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path d='M25.3 6.57a1.42 1.42 0 00-2 .24L12.46 20.68l-5.61-5.53a1.42 1.42 0 00-2.42 1v.06a1.43 1.43 0 00.42 1l6.74 6.64a1.42 1.42 0 001 .41h.06a1.43 1.43 0 001-.42l.1-.12L25.54 8.56a1.42 1.42 0 00-.24-2z' fill='%237ac24d'/></svg>");
        width: 13px;
        background-repeat: no-repeat;
        background-position: 2px;
    }
}

.large-checkbox {
    margin-right: 10px;
    label {
        font-size: 1.15rem;
    }
    label:before, label:after {
        transform: scale(1.6);
    }
}

.ui.radio:not(.toggle) {
    input:checked~.box:after, .ui.radio input:checked~label:after {
        content: '';
        background-image: none;
        width: 22px;
        height: 22px;
        top: -2px;
    }
} 

.ui.radio input~.box:before, .ui.radio input~label:before {
    background: $off-white;
    width: 22px;
    height: 22px;
    top: -2px;
}

.card-box {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
}

.saved-cred-cards {
    .ui.accordion .accordion .title, .ui.accordion .title {
        cursor: default;
    }
}

.creds-error {
    border: 1px solid $utility-red;
    background: #FFF0EF;
    border-radius: 4px;
    padding: 1rem;
    display: flex;
    margin-bottom: 2rem;
    .creds-error-icon {
        width: 14px;
        height: 14px;
        display: inline-block;
        background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M7 0C3.2 0 0 3.2 0 7s3.2 7 7 7 7-3.1 7-7-3.1-7-7-7zm-.5 3h1v5h-1V3zm.5 8.2c-.4 0-.8-.4-.8-.8s.3-.8.8-.8c.4 0 .8.4.8.8s-.4.8-.8.8z" fill="%23F5222D" fill-rule="evenodd"/></svg>');
        margin-top: 3px;
        margin-right: 0.5rem;
    }
    .creds-error-message {
        flex: 1;
    }
}