h1, h2, h3, h4, h5 {
    margin: 0 0 1rem 0;
}

h2 {
  font-size: 24px;
  letter-spacing: -0.43px;
}

h3 {
  font-size: 20px;
  color: #000;
}

h4 {
  color: #707070;
  letter-spacing: -0.29px;
}
