$primary-color: #282c34;
$secondary-color: #61dafb;
$main-font: "Source Sans Pro";
$font-color: #000;
$hover-blue: #DEF5F4;
$circle-icon-color: #C6C6C6; 
$check-circle-color: #7AC24D;
$back-button-color: #C7C7C7;
$next-button-color: #2CBCB5;
$om-red: #EE2D55;

$black: #000;
$dark-gray: #5C5C5C;
$gray: #909090;
$light-gray: #D1D1D1;
$off-white: #F4F4F4;
$white: #fff;
$red: #EE2D55;
$teal: #2CBDB6;
$light-teal: #E3FFFE;
$tiger-blue: #F2FFFE;
$green: #7AC24D;

$black: #000;
$ui-grey-1: #5C5C5C;
$ui-grey-2: #909090;
$ui-grey-3: #D8D8D8;
$off-white: #F4F4F4;
$white: #fff;
$om-red: #EE2D55;
$om-blue: #131E47;
$om-aqua: #2CBDB6;
$om-green: #7CC24E;
$utility-red: #BD0F33;
$utility-green: #69AD3C;

.om-red {
    color: $om-red;
}

@mixin shadowBox {
    border: 1px solid $ui-grey-3;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
}

$sideNavWidth: 200px;
$sideNavWidthCollapsed: 57px;
$navAnimationTimer: 0.1s ease-out;

$headerHeight: 71px;
$filterBarHeight: 60px;
$orderTableTheadHeight: 37px;

$viewportMinusHeaderHeight: calc(100vh - #{$headerHeight});

@mixin shadowBox {
    border: 1px solid $light-gray;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
}

$max-card-width: 540px;
