.services-page {
    padding: 0 24px 24px 24px;
    .dropdown-card .dropdown-card-header {
        margin: 12px 18px;
    }
    a {
        color: $teal;
        text-decoration: underline;
        font-weight: bold;
    }
}

.service-header {
    padding: 0;
    .ui.button {
        margin-left: 16px;
    }
}

.service-title {
    color: $dark-gray;
    font-size: 24px;
    font-weight: bold;
    margin-right: 30px;
    line-height: 1.3em;
}

.add-service-icon {
    width: 50px;
    height: 50px;
    // margin: 0 -12px;
    circle, line {
        fill: transparent !important;
        stroke: $teal !important;
    }
}

.card-title.add-service-label {
    color: $teal !important;
}

.pause-switch {
    display: flex;
    margin-bottom: 20px;
    .switch-label {
        flex-grow: 2;
        line-height: 1.9em;
    }
}

.dropdown-card {
    .dropdown-card-header {
        display: flex;
        align-items: center;
        // margin: 16px 18px;
    }
    .dropdown-card-title {
        flex-grow: 2;
        margin: 0 16px;
        text-align: left;
    }
    .chevron {
        width: 35px;
        height: 35px;
        transform: rotate(90deg);
        margin-top: 10px;
    }
    .chevron.down {
        transform: rotate(-90deg);
    }
}

.provider-icon-placeholder {
    border-radius: 500rem;
    background-color: $red;
    color: $white;
    text-align: center;
    font-weight: bold;
}

.provider-feature-list {
    border-radius: 4px;
    background: #eee;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem;
    ul {
        list-style-type: none;
        padding: 0 1rem;
        margin: 0 0 1rem 0;
        line-height: 2rem;
    }
    .checkmark-holder {
        width: 16px;
        display: inline-block;
        margin-right: 0.5rem;
    }
    .feature-name {
        display: inline-block;
    }
}

.ui.checkbox label, .ui.checkbox input:focus ~ label {
    color: #000 !important;
}
