
.App {
    font-family: $main-font;
    color: $font-color;
    height: 100%;
    width: 100%;
  
    a, .ui.header, .ui.checkbox label {
      color: $font-color;
      font-family: $main-font;
    }
    .circle {
      color: $circle-icon-color;
    }
    .check {
      color: $check-circle-color;
    }
    .check.circle.outline {
      color: $check-circle-color;
    }
    .stripe-fields {
      margin: 0;
      outline: 0;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      line-height: 1.21428571em;
      padding: .67857143em 1em;
      // font-size: 1em;
      background: #fff;
      border: 1px solid rgba(34,36,38,.15);
      color: $font-color ;
      border-radius: .28571429rem;
      -webkit-box-shadow: 0 0 0 0 transparent inset;
      box-shadow: 0 0 0 0 transparent inset;
    }
  }
  
  .cc-icon {
    width: 40px;
    margin: 5px 0px 0px 10px;
  }
  .cc-field {
    flex: 1;
  }

  
  .underline a{
    text-decoration: underline;
    color: $next-button-color;
  }
  .ui.card.saved-payment-info {
    background-color: #F4F4F4
  }
  
  .pointer {
    cursor: pointer;
  }
  
  .edit-mode-toggle {
    color: $next-button-color;
    cursor: pointer;
  }
  
  .StripeElement {
    font-family: $main-font;
    font-weight: 900 !important;
  }
  
  .seg-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .content {
    flex: 1;
  }
  
  .button {
    background: $next-button-color;
  }
  .ACH-button {
    margin: 20px 0 0 0 !important;
    background: $om-red !important;
  }
  .ACH-verify-button {
    margin: 20px 0 0 0 !important;
    background: $next-button-color !important;
  }
  .ACH-setup {
    margin: 0 auto !important;
    background: transparent !important;
    border: none !important;
    text-decoration: underline;
    text-align: center;
    font-weight: 700;
    font-size: large;
    color: #5C5C5C;
    cursor: pointer;
    stroke: $next-button-color;
  }
  
  .back-button {
    color: $back-button-color !important;
    background-color: #FFF !important;
    width: 70px !important;
  }
  
  .ui.basic.segment.home:hover {
    // background-color: $hover-blue
    cursor: default
  }
  
  .ui.grid {
    .row.header-row, .row.header-row-no-top-space {
      padding-bottom: 0px;
    }
    .row.header-row {
      padding-top: 7px;
    }
    .row.header-row-no-top-space {
      padding-top: 0px;
    }
  }
  
  .inline {
    display: inline-block;
  }
  
 
  .divider {
    border-top: 1px solid #D0D0D0;
    margin-top: 20px;
    padding-top: 20px;
  }

  .divider.card-divider {
    margin-left: -64px;
    margin-right: -64px;
  }
  
  .terms-policy {
    a {
      color: #2CBCB5;
      text-decoration: underline !important;
    }
  }
  
  .full-height-flex {
    display: flex;
    min-height: calc(100vh - 91px);
    flex-direction: column;
  }
  
  .vert-center-parent{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
    flex-wrap: wrap;
    height: 55vh;
  }
  
  .vert-center-child {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }
  
  div.ui.segment.service-segment {
    background-color: #e4f6ff;
    box-shadow: none;
    border: none;
  }
  
  div.ui.card.login-card {
    padding: 1.5em 0.5em;
    opacity: 0.95;
    // .header {
    //   margin-bottom: 1.5em;
    // }
    .forms-space {
      margin: 2em 0em;
    }
  }

  .ui.inverted.segment.help-segment {
    background: #EE2D55;
    .underline a {
      color: rgba(255,255,255,.9);
    }
  }
  
  .ui.grid.cc-forms {
    .row {
      padding: 0px;
      margin-bottom: 12px;
      .cc-acct-info {
        padding-top: 7px;
      }
    }
  }
  .ui.segment.credit-card-segment {
    box-shadow: 0px 3px 6px rgba(0, 0, 0,0.29);
    width: 100%;
    background-color: #F4F4F4;
    border: none;
  }
  .billing-info {
    margin-top: 2.5em;
    margin-bottom: .5em;
  }
  .ui.horizontal.segments {
    background: #7AC24D;
    color: #FFF;
    text-align: center;
    border: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0,0.29);
  }
  .ui.horizontal.segments:not(.stackable)>.segment:first-child {
    border-left: none;
  }
  .ui.horizontal.segments>.segment {
    border-left: 1px solid rgb(255,255,255);
  }
  
  i.big.exclamation {
    color: $om-red;
  }
  
  .reach-zip-required {
    color: $om-red;
  }
  
  .service-drivers {
    margin-bottom: 10px;
    p {
      margin-bottom: 7px;
    }
  }
  
  .radio-group-bottom-space {
    margin-bottom: 5px !important;
    .non-active {
      padding-bottom: 10px;
      cursor: pointer;
      color: #909090;
      border-bottom: 3px solid #909090;
    } 
  }
  .radio-label{
    display: block;
    margin: 0 0 .28571429rem 0;
    color: rgba(0,0,0,.87);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
  }
  
  
  .svg-stroke {
    stroke: $next-button-color;
  }
  .svg-fill {
      fill: $next-button-color;
  }
  .active {
      .svg-stroke {
          stroke: $white;
      }
      .svg-fill {
          fill: $white;
      }
  }
  
  
  .price-plan {
    margin-bottom: 10px;
  }
  .billing-info-segment {
    margin: 1em 0em !important;
    
  }
  
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-top: 50px;
    padding: 0 20px;
    background-color: #F4F4F4;
    color: #909090;
    .underline {
      text-decoration: underline;
    }
    border-top: 1px solid #0000001F;
    a {
      color: #909090;
    }
    .footer-left{
      display: flex;
      align-items: center;
    }
  }
  
  .flex-space {
    display: flex;
    justify-content: space-between;
  }
  .flex-around {
    display: flex;
    justify-content: space-evenly;
  }
  .f2 { 
    font-size: 2.25rem; 
  }

  .locations-list-box {
    background: #FAFAFA;
    border-radius: 4px;
    border: 1px solid #DFDFE0; 
    margin-bottom: 30px;
  }
  
  .locations-segment-divider {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DFDFE0;
    padding: 15px 20px;
    cursor: default;
    font-family: $main-font;
    .restaurant-name {
      margin: auto 0
    }
    .locations-action {
      text-decoration: underline;
      color: #909090;
      cursor: pointer;
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .locations-segment-divider:last-child {
    border-bottom: 0;
  }

  .main-content-container {
    background: #fff;
    max-width: 640px !important;
    margin: 0 auto;
    box-shadow: 0px 1px 3px #00000033;
    border: 1px solid #F3F4F5;
    border-radius: 4px;
  }

  .page-stepper {
    height: 80px;
    border-bottom: 1px solid #0000001F;
    position: relative;
    .page-stepper-inner {
      width: 315px;
      margin: 0 auto;
    }
    .page-stepper-bg-line {
      width: 267px;
      margin: 0 auto;
      padding-top: 34px;
      border-bottom: 1px solid #E6E6E6;
    }
    .page-stepper-list-container {
      position: absolute;
      top: 22px;
      width: 315px;
    }
    ul { 
      width: 315px;
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      color: #00000089;
      li {
        margin: 0;
        padding: 0;
        font-size: 12px;
        text-align: center;
        font-weight: bold;
        width: 65px;
        .step-circle {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          background: #fff;
          border: 1px solid #E6E6E6;
          margin: 0 auto;
        }
        .step-circle.complete {
          background: #7CC24E;
          border: 1px solid #7CC24E;
        }
        .step-circle.current {
          background: #707070;
          border: 1px solid #707070;
        }
      }
    }
  }

  .main-content {
    padding: 64px 64px 28px 64px;
  }

  .tab-switcher {
    border-radius: 4px;
    border: 1px solid $next-button-color;
    .tab { 
      font-size: 17px;
      padding: 11px 0 9px 0;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;
    }
    .tab:first-child {
      border-radius: 4px 0 0 4px;
    }
    .tab:last-child {
      border-radius: 0 4px 4px 0;
    }
    .tab.active {
      background: $next-button-color;
      color: $white;
    }
    .tab.inactive {
      background: $white;
      color: $next-button-color;
    }
  }

  .list-display li {
    margin-bottom:.4rem;
    font-size:1.1rem;
  }
  .list-checkmarks {
    width: 50%;
    padding-left:1.5rem;
  }
  .list-checkmarks li {
    list-style-type:none;
    padding-left:1rem;
    text-align: left;
    background-repeat: no-repeat;
    padding-left: 30px;
    background-image: url("data:image/svg+xml;utf8,<svg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path d='M25.3 6.57a1.42 1.42 0 00-2 .24L12.46 20.68l-5.61-5.53a1.42 1.42 0 00-2.42 1v.06a1.43 1.43 0 00.42 1l6.74 6.64a1.42 1.42 0 001 .41h.06a1.43 1.43 0 001-.42l.1-.12L25.54 8.56a1.42 1.42 0 00-.24-2z' fill='%237ac24d'/></svg>");
    background-size: 19px;
  }
  